import React, { useState } from 'react';
import styled from '@emotion/styled';
import { slide as Menu } from 'react-burger-menu';
import { useScrollPosition } from '@n8tb1t/use-scroll-position';
import { heightHeader, color, useLocale, menuStyles, WIDTH_MOBILE } from '../utility';
import { CtaButton } from './';
import discordSvg from '../asset/discord.svg';
import twitterSvg from '../asset/twitter.svg';


export function Header() {
    const locale = useLocale();
    const [headerActive, setHeaderActive] = useState(false);
    const [mobileMenuOpen, setMobileMenuOpen] = useState(false);

    useScrollPosition(({currPos}) => {
        if (currPos?.y < -heightHeader) {
            setHeaderActive(true);
        } else {
            setHeaderActive(false);
        }
    })

    return (
        <StyledHeader className={!!headerActive ? 'active' : ''}>
            <StyledFill className={!!headerActive ? 'active' : ''} />
            <StyledBorder className={!!headerActive ? 'active' : ''} />
            <StyledSplit>
                <StyledPageMenu className={!!headerActive ? 'active' : ''}>
                    <li><a href="#roadmap">{locale.menu.roadmap}</a></li>
                    <li><a href="#rarity">{locale.menu.rarity}</a></li>
                    <li><a href="#team">{locale.menu.team}</a></li>
                </StyledPageMenu>
                <StyledMobileMenuWrapper>
                    <StyledMobileMenu 
                        styles={menuStyles} 
                        noTransition
                        isOpen={mobileMenuOpen}
                        onOpen={() => setMobileMenuOpen(true)}
                        onClose={() => setMobileMenuOpen(false)}
                    >
                        <li><a onClick={() => setMobileMenuOpen(false)} className="menu-item" href="#roadmap">{locale.menu.roadmap}</a></li>
                        <li><a onClick={() => setMobileMenuOpen(false)} className="menu-item" href="#rarity">{locale.menu.rarity}</a></li>
                        <li><a onClick={() => setMobileMenuOpen(false)} className="menu-item" href="#team">{locale.menu.team}</a></li>
                    </StyledMobileMenu>
                </StyledMobileMenuWrapper>
                <StyledRightMenu>
                    <a href="https://twitter.com/bicameralnft" target="_blank" rel="noreferrer">
                        <StyledSocialImg src={twitterSvg} alt="Twitter" />
                    </a>
                    <a href="https://discord.gg/MVZ7KEVT" target="_blank" rel="noreferrer">
                        <StyledSocialImg src={discordSvg} alt="Discord" />
                    </a>
                    <CtaButton>
                        {locale.cta_wallet}
                    </CtaButton>
                </StyledRightMenu>
            </StyledSplit>
        </StyledHeader>
    );
}


const StyledHeader = styled.div`
    position: fixed;
    z-index: 999;
    top: 0;
    left: 0;
    height: ${heightHeader}px;
    width: 100%;
    min-width: 310px;

    &.active {
        backdrop-filter: blur(6px);
    }
`;

const StyledFill = styled.div`
    transition: all 0.1s linear 0s;
    position: absolute;
    z-index: -1;
    top: 0;
    left: 0;
    height: 100%;
    width: 100%;
    background: #000;
    pointer-events: none;
    transform: translateY(-100%);
    opacity: 0.8;

    &.active {
        // opacity: 1;
        transform: translateY(0%);
    }
`;

const StyledBorder = styled.div`
    transition: transform 0.3s linear 0s;
    position: absolute;
    z-index: -1;
    width: 100%;
    height: 1px;
    bottom: 0;
    left: 50%;
    transform: translateX(-50%) scale(1, 1);
    background: ${color.dark};
    opacity: 0.25;

    &.active {
        transform: translateX(-50%) scale(0, 1);
    }
`;

const StyledSplit = styled.div`
    display: flex;
    flex-direction: row;
    flex-wrap: nowrap;
    justify-content: space-between;
    align-items: center;
    height: 100%;
    width: 100%;
    padding: 0 26px;
`;

const StyledPageMenu = styled.ul`
    list-style: none;
    margin: 0;
    padding: 0;

    li {
        margin: 0 20px 0 0;
        float: left;
    }

    a {
        transition: opacity 0.1s linear 0s, color 0.1s linear 0s;
        font-weight: 600;
        font-size: 18px;
        text-decoration: none;
        color: ${color.dark};

        &:hover {
            opacity: 0.85;
        }
    }

    &.active {
        a {
            color: ${color.font};
        }
    }

    @media only screen and (max-width: 560px) {
        display: none;
    }
`;

const StyledMobileMenu = styled(Menu)`
    position: fixed;
    top: 0;
    left: 0;
`;

const StyledMobileMenuWrapper = styled.div`
    opacity: 0;
    pointer-events: none;
    z-index: 9999;

    li {
        float: none;
        display: block;
    }

    a {
        transition: opacity 0.1s linear 0s, color 0.1s linear 0s;
        font-weight: 600;
        font-size: 18px;
        text-decoration: none;
        color: ${color.dark};
        clear: both;

        &:hover {
            opacity: 0.85;
        }
    }

    @media only screen and (max-width: 560px) {
        opacity: 1;
        pointer-events: auto;
    }
`;

const StyledRightMenu = styled.div`
    display: flex;
    flex-direction: row;
    flex-wrap: nowrap;
    justify-content: flex-end;
    align-items: center;

    a {
        transition: opacity 0.1s linear 0s;
        margin-right: 10px;
        display: inline-block;
        max-height: 34px;
        max-width: 34px;
        color: transparent;
        font-size: 5px;

        &:hover {
            opacity: 0.9;
        }
    }
    div {
        margin-left: 10px;

        @media only screen and (max-width: ${WIDTH_MOBILE}px) {
            margin-left: 0px;
        }
    }
`;

const StyledSocialImg = styled.img`
    width: 34px;
    height: 34px;
`;