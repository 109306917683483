import React from 'react';
import styled from '@emotion/styled';
import { keyframes } from '@emotion/react';
import { color, WIDTH_MOBILE, useLocale } from '../utility';
import ReactTooltip from 'react-tooltip';

type CtaButtonOptions = {
    readonly children?: JSX.Element | string | number;
}

export function CtaButton({ children }: CtaButtonOptions) {
    const locale = useLocale();
    const isMobile = /iPhone|iPad|iPod|Android/i.test(navigator.userAgent);

    return (
        <>
            <ReactTooltip disable={isMobile} />
            <StyledButton data-tip={locale.coming_soon} data-effect="solid" data-clickable={true}>
                <div className="fill"></div>
                <div className="border"></div>
                <div className="border-faux"></div>
                {children}
            </StyledButton>
        </>
    );
}

const AnimHoverOpacity = keyframes`
    50% {
        opacity: 0.7;
    }
`;

const AnimHover = keyframes`
    50% {
        box-shadow: 0px -1px 26px 7px rgba(0,0,0,0.1);
    }
`;

const StyledButton = styled.div`
    transition: color 0.1s linear 0s, background 0.1s linear 0s;
    position: relative;
    color: ${color.fontButton};
    padding: 14px 20px;
    cursor: pointer;
    border-radius: 6px;
    font-size: 16px;
    font-weight: bold;
    letter-spacing: 0.3px;

    @media only screen and (max-width: ${WIDTH_MOBILE}px) {
        font-size: 14px;
        padding: 14px 14px;
    }

    .fill {
        transition: transform 0.1s linear 0s, opacity 0.1s linear 0s;
        position: absolute;
        z-index: -1;
        height: 100%;
        width: 100%;
        margin: 0;
        top: 0;
        left: 0;
        transform: scale(1, 1);
        background: ${color.backgroundButton};
        opacity: 1;
        border-radius: 6px;
        pointer-events: none;
    }

    .border {
        transition: transform 0.1s linear 0.05s, border 0.3s ease-out 0.1s;
        position: absolute;
        z-index: -1;
        height: 100%;
        width: 100%;
        margin: 0;
        top: 0;
        left: 0;
        border: 5px solid ${color.backgroundButton};
        border-radius: 6px;
        transform: scale(0, 0);
    }

    .border-faux {
        transition: transform 0.4s linear 0s, opacity 0.4s linear 0s;
        position: absolute;
        z-index: -1;
        height: 100%;
        width: 100%;
        margin: 0;
        top: 0;
        left: 0;
        opacity: 1;
        border: 2px solid ${color.backgroundButton};
        border-radius: 6px;
        transform: scale(0, 0);
    }

    &:hover {
        color: ${color.backgroundButton};
        background: ${color.fontButton};
        animation: ${AnimHoverOpacity} 2.3s ease infinite;

        .fill {
            opacity: 0;
            transform: scale(0, 0);
        }
        .border {
            transform: scale(1, 1);
            border: 0px solid transparent;
            animation: ${AnimHover} 2.3s ease infinite;
        }
        .border-faux {
            transform: scale(1, 1);
            opacity: 0;
        }
    }
`;