import React from 'react';

type HexagonOptions = {
    readonly className?: any;
};

export function Hexagon({ className }: HexagonOptions) {
    return (
        <svg className={className} xmlns="http://www.w3.org/2000/svg" width="304" height="352" viewBox="0 0 304 352">
            <path pathLength="1" d="M2,88.602540 L152,2 302,88.602540 v173.215081 L152,350.410161 2,261.807621 z"/>
        </svg>
    );
}