import React from 'react';

type LogoOptions = {
    readonly className?: string;
}

export function LogoV2({ className }: LogoOptions) {
    return (
        <svg className={className} width="307" height="51" viewBox="0 0 307 51" xmlns="http://www.w3.org/2000/svg">
            <path d="M28.6089 30.6865C28.6089 25.8625 24.8929 21.4945 19.6779 21.4945C14.6589 21.4945 10.7469 25.6015 10.7469 30.6215C10.7469 35.9665 14.5279 39.9435 19.5479 39.9435C24.6329 39.9435 28.6089 35.9015 28.6089 30.6865ZM0.316895 48.8085V0.569546H11.1379V15.4975C14.0069 12.4335 17.6569 11.3905 21.4379 11.3905C30.8249 11.3905 39.4309 18.4315 39.4309 30.4915C39.4309 41.6385 32.3249 50.0475 21.1779 50.0475C16.8099 50.0475 12.4419 48.0265 10.8119 44.9625H10.6819V48.8085H0.316895Z" />
            <path d="M122.139 30.6865C122.139 25.6015 118.032 21.4945 112.947 21.4945C107.993 21.4945 104.082 25.6015 104.082 30.8165C104.082 35.8365 108.058 39.9435 113.078 39.9435C118.163 39.9435 122.139 35.9015 122.139 30.6865ZM122.139 48.8085V44.8975H122.009C120.444 48.0925 116.142 50.0475 111.383 50.0475C100.692 50.0475 93.2605 41.6385 93.2605 30.6865C93.2605 19.9955 101.018 11.3905 111.383 11.3905C115.816 11.3905 119.923 13.1505 122.009 16.4755H122.139V12.6295H132.961V48.8085H122.139Z" />
            <path d="M180.734 48.809V29.317C180.734 23.646 178.909 21.56 175.193 21.56C171.086 21.56 169.196 24.037 169.196 28.796V48.809H158.374V28.861C158.374 24.168 156.679 21.56 152.964 21.56C148.726 21.56 146.836 24.298 146.836 29.317V48.809H136.015V12.629H146.119V16.475H146.249C148.14 13.086 151.595 11.391 156.223 11.391C160.917 11.391 164.372 13.737 166.653 17.844C169.261 13.737 173.172 11.391 178.126 11.391C186.21 11.391 191.555 16.345 191.555 26.449V48.809H180.734Z" />
            <path d="M220.445 26.7101C219.598 22.9941 216.338 20.5171 212.166 20.5171C208.255 20.5171 204.865 22.8641 204.018 26.7101H220.445ZM203.887 34.0111C204.474 38.1181 207.929 40.8561 212.362 40.8561C215.295 40.8561 217.185 39.7481 218.946 37.4661H230.028C226.573 45.3541 219.989 49.9821 212.362 49.9821C201.931 49.9821 193.196 41.5731 193.196 30.8171C193.196 20.3871 201.54 11.3911 212.166 11.3911C222.987 11.3911 231.266 19.9301 231.266 31.1431C231.266 32.1861 231.201 33.0331 231.006 34.0111H203.887Z" />
            <path d="M233.17 48.809V12.629H243.34V16.475H243.47C245.36 12.629 248.359 11.391 252.531 11.391V22.277C246.078 22.342 243.991 24.754 243.991 28.861V48.809H233.17Z" />
            <path d="M281.546 30.6865C281.546 25.6015 277.439 21.4945 272.354 21.4945C267.4 21.4945 263.489 25.6015 263.489 30.8165C263.489 35.8365 267.465 39.9435 272.485 39.9435C277.569 39.9435 281.546 35.9015 281.546 30.6865ZM281.546 48.8085V44.8975H281.416C279.851 48.0925 275.549 50.0475 270.79 50.0475C260.099 50.0475 252.667 41.6385 252.667 30.6865C252.667 19.9955 260.425 11.3905 270.79 11.3905C275.223 11.3905 279.33 13.1505 281.416 16.4755H281.546V12.6295H292.367V48.8085H281.546Z" />
            <path d="M295.343 0.56897H306.164V48.809H295.343V0.56897Z" />
            <path d="M51.8822 34.6937V12.6297H41.0612V45.5147L51.8822 34.6937Z" />
            <path d="M81.2306 35.0542C79.6006 38.1832 77.0586 39.8782 73.4076 39.8782C68.3886 39.8782 64.8036 35.9672 64.8036 30.6862C64.8036 25.6022 68.1926 21.4952 73.1476 21.4952C76.8636 21.4952 79.6656 23.1902 81.2306 26.5802H92.1826C90.3186 18.1032 83.5746 12.3492 75.0716 11.5042L54.0176 32.5582C54.9696 42.3392 63.0456 49.9822 73.0826 49.9822C82.3386 49.9822 90.0966 44.1152 92.2476 35.0542H81.2306Z" />
        </svg>
    );
}