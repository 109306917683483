import React, { useState, useRef, useEffect } from 'react';
import styled from '@emotion/styled';
import { 
    PageOptions, 
    color, 
    useLocale, 
    useWindowSize, 
    WIDTH_TABLET
} from '../utility';
import { LogoV2 } from '../asset';
import heroBackground from '../asset/hero.jpg';
import heroAnimation from '../asset/hero.webm';
import heroAnimationSafari from '../asset/hero.mp4';

export function PageHome({ 
    id, 
    showIntroText = false
}: PageOptions) {
    const locale = useLocale();
    const [videoLoaded, setVideoLoaded] = useState(false);
    const [width, setWidth] = useState(0);
    const [windowWidth, windowHeight] = useWindowSize();
    const ref = useRef(null);

    useEffect(() => {
        // @ts-ignore
        if (ref?.current?.clientWidth) {
            // @ts-ignore
            setWidth(ref?.current?.clientWidth);
        }
    }, [windowWidth]);

    return (
        <StyledPage 
            id={id} 
            ref={ref} 
            height={windowHeight > width ? `${width}px` : `100%`}
        >
            <StyledHeroAssetDynamic>
                <video 
                    className={videoLoaded ? 'video-loaded video' : 'video'}
                    /* @ts-ignore */
                    autoPlay="autoplay" 
                    muted={true} 
                    loop={true}
                    playsInline
                    onLoadedData={() => setVideoLoaded(true)}
                >
                    <source src={heroAnimation} type="video/webm" />
                    <source src={heroAnimationSafari} type="video/mp4" />
                </video> 
            </StyledHeroAssetDynamic>
            <StyledHeroAssetStatic className={videoLoaded ? 'video-loaded' : ''} />
            <StyledHeroOverlay className={videoLoaded ? 'video-loaded' : ''} />
            <StyledHeroText>
                <div>
                    {/* <StyledLogo /> */}
                    <StyledRibbon />
                    <StyledLogoV2 />
                    <StyledDescription>{locale.description}</StyledDescription>

                    {!!showIntroText && 
                        <StyledIntroText>{locale.introText}</StyledIntroText>
                    }
                </div>
            </StyledHeroText>
        </StyledPage>
    );
}

const StyledRibbon = styled.div`
    position: absolute;
    top: -1069px;
    left: 26px;
    height: 2600px;
    width: 600px;
    z-index: 9;
    background: ${color.purple};
    mix-blend-mode: exclusion;
    transform: rotateZ(45deg);
`;

const StyledPage = styled.div<{height: string;}>`
    position: relative;
    height: ${({height}) => height};
    width: 100%;
    overflow: hidden;
`;

const StyledHeroAssetStatic = styled.div`
    position: absolute;
    top: 0;
    left: 0;
    height: 100%;
    width: 100%;
    background-image: url(${heroBackground});
    background-position: center;
    background-size: cover;
    pointer-events: none;
    opacity: 1;

    &.video-loaded {
        opacity: 0;
    }
`;

const StyledHeroAssetDynamic = styled.div`
    position: absolute;
    top: 0;
    left: 0;
    height: 100%;
    width: 100%;
    overflow: hidden;

    .video {
        position: absolute;
        top: 0;
        left: 0;
        max-width: 100%;
        top: 50%;
        transform: translateY(-50%);
        opacity: 0;

        &.video-loaded {
            opacity: 1;
        }
    }
`;

const StyledHeroOverlay = styled.div`
    position: absolute;
    top: 0;
    left: 0;
    height: 100%;
    width: 100%;
    background: ${color.light};
    opacity: 0;

    &.video-loaded {
        opacity: 0.6;
    }
`;

const StyledHeroText = styled.div`
    position: absolute;
    height: 160px;
    padding: 0 20px;
    margin-left: 12px;
    text-align: left;
    top: 50%;
    left: 50%;
    transform: translateY(-50%) translateX(-50%) scale(2, 2);
    display: flex;
    flex-direction: column;
    flex-wrap: nowrap;
    justify-content: space-between;
    align-items: center;
    border-left: 12px solid ${color.font};
    mix-blend-mode: exclusion;

    @media only screen and (max-width: ${WIDTH_TABLET}px) {
        transform: translateY(-50%) translateX(-50%) scale(1.7, 1.7);
    }
    @media only screen and (max-width: 820px) {
        transform: translateY(-50%) translateX(-50%) scale(1.4, 1.4);
    }
    @media only screen and (max-width: 680px) {
        transform: translateY(-50%) translateX(-50%) scale(1.1, 1.1);
    }
    @media only screen and (max-width: 540px) {
        transform: translateY(-50%) translateX(-50%) scale(0.8, 0.8);
    }
    @media only screen and (max-width: 390px) {
        transform: translateY(-50%) translateX(-50%) scale(0.7, 0.7);
    }
    @media only screen and (max-width: 360px) {
        transform: translateY(-50%) translateX(-50%) scale(0.6, 0.6);
    }
    @media only screen and (max-width: 320px) {
        transform: translateY(-50%) translateX(-50%) scale(0.55, 0.55);
    }
`;

const StyledDescription = styled.div`
    font-weight: 500;
    font-size: 20px;
    color: ${color.font};
`;
const StyledIntroText = styled.div`
    font-weight: 500;
    font-size: 12px;
    max-width: 280px;
    margin-top: 6px;
    color: ${color.font};

    @media only screen and (max-width: 540px) {
        font-size: 15px;
        max-width: 320px;
    }
    @media only screen and (max-width: 390px) {
        font-size: 16px;
        max-width: 340px;
    }
`;
const StyledLogoV2 = styled(LogoV2)`
    fill: ${color.font};
    width: 400px;
    height: 67px;
`;