export const color = {
    red: '#932d2d',
    green: '#00ff00',
    purple: '#ff00ff',
    blue: '#3a5dae',
    dark: '#fafafa',
    lessDark: '#a7a7a7',
    light: '#1A1B1C',
    background: '#1A1B1C',
    font: '#f2f2f2',
    fontDark: '#1A1B1C',
    backgroundButton: '#1A1B1C',
    fontButton: '#ffffff',
    black: '#111111'
};

// lightmode
// export const color = {
//     red: '#932d2d',
//     green: '#00ff00',
//     purple: '#ff00ff',
//     blue: '#3a5dae',
//     dark: '#1A1B1C',
//     lessDark: '#c7c7c7',
//     light: '#ffffff',
//     background: '#f2f2f2',
//     font: '#1A1B1C',
//     backgroundButton: '#1A1B1C',
//     fontButton: '#ffffff'
// };

export const convertHexToRGBA = (hexCode: string, opacity: number) => {
    let hex = hexCode.replace('#', '');
    
    if (hex.length === 3) {
        hex = `${hex[0]}${hex[0]}${hex[1]}${hex[1]}${hex[2]}${hex[2]}`;
    }    
    
    const r = parseInt(hex.substring(0, 2), 16);
    const g = parseInt(hex.substring(2, 4), 16);
    const b = parseInt(hex.substring(4, 6), 16);

    return `rgba(${r},${g},${b},${opacity})`;
};