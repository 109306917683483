import styled from '@emotion/styled';
import { color } from './color-palette';

export const WIDTH_TABLET = 960;
export const WIDTH_MOBILE = 560;
export const heightHeader = 80;

export const StyledPageCenter = styled.div<{overrideOverflowHidden?: boolean}>((props) => `
    width: 100%;
    max-width: 1000px;
    min-height: 300px;
    padding: 0 15px;
    padding-bottom: 25px;
    margin: 0 auto 16px auto;
    border-top: 1px solid transparent;
    overflow: ${props.overrideOverflowHidden ? 'visible' : 'auto'};

    &.center-text {
        text-align: center;
    }

    &.no-overflow {
        overflow: hidden;
    }
`);

export const StyledSectionTitle = styled.div`
    text-align: center;
    font-size: 58px;
    font-weight: 500;
    margin: 50px auto 40px;
    padding-bottom: 10px;
    display: block;
    text-align: left;
    border-bottom: 1px solid ${color.font};

    @media only screen and (max-width: ${WIDTH_MOBILE}px) {
        margin: 30px auto 20px;
        font-size: 32px;
    }
`;

export const StyledSectionDescription = styled.div`
    margin-top: 8px;
    text-align: center;
    opacity: 0.5;
    font-size: 14px;
    display: block;
`;