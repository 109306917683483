import React, { useEffect, useState, useRef } from 'react';
import styled from '@emotion/styled';
import ReactTooltip from 'react-tooltip';
import { keyframes } from '@emotion/react';
import { 
    PageOptions,
    StyledPageCenter,
    StyledSectionTitle,
    useLocale,
    color,
    convertHexToRGBA,
    useWindowSize
} from '../utility';
import { Hexagon } from '../asset';

type StepType = {
    readonly percentage_emphasis?: number;
    readonly complete_or_in_progress?: boolean;
    readonly label?: string;
    readonly percentage?: number;
    readonly description?: string;
    readonly posY?: string;
    readonly posXLeft?: string;
    readonly posXRight?: string;
    readonly width?: string;
}

type NodeOptions = {
    readonly step: StepType;
    readonly index: number;
    readonly array: StepType[];
    readonly children?: any;
}

const addDivider = (html: string, isDark = false) => {
    return `<div><div class="tooltip-divider${isDark ? '-dark' : ''}"></div>${html}</div>`
}

function Node({step, index, array, children}: NodeOptions) {
    const isFirst = index === 0;
    const isLast = index === array.length - 1;
    const isHexagon = !!step?.percentage_emphasis;
    const nextStep = index < array.length - 1 ? array[index + 1] : step;
    const isCircCirc = !isHexagon && !nextStep?.percentage_emphasis;
    const isHexHex = isHexagon && nextStep?.percentage_emphasis;
    const isCircHex = !isHexagon && nextStep?.percentage_emphasis;
    const isHexCirc = isHexagon && !nextStep?.percentage_emphasis;

    if (isHexagon) {
        return (
            <StyledHexagonWrapper 
                className={`node hexagon ${!!step?.complete_or_in_progress ? 'complete' : ''} ${isFirst ? 'first' : ''} ${isLast ? 'last' : ''} ${isCircCirc ? 'circCirc' : ''} ${isHexHex? 'hexHex' : ''} ${isCircHex ? 'circHex' : ''} ${isHexCirc ? 'hexCirc' : ''}`}
            >
                    <StyledHexagon className={`p${step?.percentage_emphasis}`} />
                    <span className="label-milestone">{step?.percentage_emphasis}%</span>
                    {
                        !!step?.label &&
                        <div className={`hover ${!step?.description && !step?.percentage ? 'noHover' : ''}`}>
                            <StyledLabelOffset width={step?.width} posY={step?.posY} posXLeft={step?.posXLeft} posXRight={step?.posXRight} className={`label ${!!children ? 'mint-parent' : ''}`}>
                                {step?.label}
                                {
                                    !!step?.description && 
                                    <span className="description" dangerouslySetInnerHTML={{__html: addDivider(step?.description, step?.label === 'Public Mint')}} />
                                }
                            </StyledLabelOffset>
                        </div>
                    }
                    {children}
            </StyledHexagonWrapper>
        );
    } else {
        return (
            <StyledCircleNode className={`node circle ${!!step?.complete_or_in_progress ? 'complete' : ''} ${isFirst ? 'first' : ''} ${isLast ? 'last' : ''} ${isCircCirc ? 'circCirc' : ''} ${isHexHex? 'hexHex' : ''} ${isCircHex ? 'circHex' : ''} ${isHexCirc ? 'hexCirc' : ''}`}>
                {!!step?.label &&
                    <div className={`hover ${!step?.description && !step?.percentage ? 'noHover' : ''}`}>
                        <StyledLabelOffset style={{zIndex: 10}} width={step?.width} posY={step?.posY} posXLeft={step?.posXLeft} posXRight={step?.posXRight} className={`label ${!!step?.percentage ? 'mint' : ''}`}>
                            {
                                !!step?.percentage && 
                                <span className="percentage">{step?.percentage}% Sold</span>
                            }
                            <span className="mint-label">
                                {step?.label}
                            </span>
                            {
                                !!step?.description && 
                                <span className="description" dangerouslySetInnerHTML={{__html: addDivider(step?.description)}} />
                            }
                        </StyledLabelOffset>
                    </div>
                }
            </StyledCircleNode>
        )
    }
}

export function PageRoadmap(
    { id }: PageOptions
) {
    const locale = useLocale();
    const [width, setWidth] = useState(0);
    const [windowWidth, ] = useWindowSize();
    const ref = useRef(null);

    useEffect(() => {
        // @ts-ignore
        if (ref?.current?.clientWidth) {
            // @ts-ignore
            setWidth(ref?.current?.clientWidth);
        }
    }, [windowWidth]);

    return (
        <StyledPage>
            <StyledPageCenter overrideOverflowHidden id={id} className="center-text" ref={ref}>
                <StyledRibbonRoadmapWrapper>
                    <StyledRibbonRoadmap />
                </StyledRibbonRoadmapWrapper>
                <StyledSectionTitle>{locale.section_title.roadmap}</StyledSectionTitle>
                <StyledRoadmapPosition className={width <= 930 ? 'mobile' : ''}>
                    <StyledRoadmapListContainer className={width <= 930 ? 'mobile' : ''}>
                        <ReactTooltip className="tooltip-container" html={true} multiline={true} clickable={true} />
                        <ul>
                            {locale.roadmap.steps_launch.map((stepLaunch) => {
                                return (!stepLaunch?.connect_steps_mint ? (
                                    <li>
                                        <span>
                                            <div className={stepLaunch.complete_or_in_progress ? 'circ complete' : 'circ'}></div>
                                            {
                                                !!stepLaunch.description ? (
                                                    <p className="description" data-tip={stepLaunch.description}>
                                                        {stepLaunch.label}
                                                    </p>
                                                ) : <p>{stepLaunch.label}</p>
                                            }
                                        </span>
                                    </li>
                                ) : (
                                    <li>
                                        <span><div className={stepLaunch.complete_or_in_progress ? 'circ complete' : 'circ'}></div>{stepLaunch.label}</span>
                                        <ul>
                                            {locale.roadmap.steps_mint.map((stepMint) => {
                                                return (
                                                    <li>
                                                        <span>
                                                            <div className="circ"></div>
                                                            {stepMint.percentage}% - {stepMint.label}
                                                        </span>
                                                    </li>
                                                );
                                            })}
                                        </ul>
                                    </li>
                                ));
                            })}
                        </ul>
                    </StyledRoadmapListContainer>
                    <StyledRoadmapDiagramContainer>
                        {locale.roadmap.steps_launch.map((stepLaunch, index, array) => {
                            return (
                                (!stepLaunch?.connect_steps_mint ?
                                    <Node key={Math.random()} step={stepLaunch} index={index} array={array} />
                                    :
                                    (
                                        <Node 
                                            key={Math.random()} 
                                            step={stepLaunch} 
                                            index={index}
                                            array={array}
                                            children={
                                                <StyledRoadmapDiagramContainer className="mint">
                                                    {locale.roadmap.steps_mint.map((stepMint, indexMint, arrayMint) => {
                                                        return (
                                                            <Node 
                                                                key={Math.random()} 
                                                                step={stepMint} 
                                                                index={indexMint} 
                                                                array={arrayMint} 
                                                            />
                                                        );
                                                    })}
                                                </StyledRoadmapDiagramContainer>
                                            }
                                        />
                                    )
                                )
                            );
                        })}
                    </StyledRoadmapDiagramContainer>
                </StyledRoadmapPosition>
            </StyledPageCenter>
        </StyledPage>
    );
}

const lineLength = 30;

const StyledPage = styled.div<{overrideOverflowHidden?: boolean}>((props) => `
    position: relative;
    overflow: ${props.overrideOverflowHidden ? 'visible' : 'hidden'};
    // will-change: opacity;
    // transform: translateZ(0);
`);

const StyledRibbonRoadmapWrapper = styled.div`
    position: absolute;
    top: 0;
    left: 50%;
    transform: translateX(-50%);
    height: 0;
    width: 2px;
    z-index: 3;
    mix-blend-mode: exclusion;
`;

const glitchAnim = keyframes`
    20% {
        transform: translateX(-80%);
    }
    25% {
        transform: translateX(-115%);
    }
    45% {
        transform: translateX(-130%);
    }
    50% {
        transform: translateX(-90%);
    }
    55% {
        transform: translateX(-150%);
    }
    70% {
        transform: translateX(-85%);
    }
    90% {
        transform: translateX(-140%);
    }
`;

const StyledRibbonRoadmap = styled.div`
    position: absolute;
    top: -1190px;
    left: -725px;
    height: 3000px;
    width: 20px;
    z-index: 9;
    background: ${color.green};
    mix-blend-mode: exclusion;
    transform: translateX(-50%) rotateZ(45deg) translate3d(0,0,0);
    will-change: opacity;
    pointer-events: none;

    @media only screen and (max-width: 900px) {
        left: -535px;
    }

    @media only screen and (max-width: 640px) {
        width: 480px;
        left: -585px;

        &:after {
            display: none;
        }
    }

    &:after {
        content: '';
        position: absolute;
        height: 100%;
        width: 100%;
        top: -220px;
        left: 26px;
        background: ${color.purple};
        mix-blend-mode: exclusion;
        transform: translateX(-120%);
        animation: ${glitchAnim} 8s ease-in-out infinite;
    }

    // &:before {
    //     content: '';
    //     position: absolute;
    //     height: 100%;
    //     width: 180px;
    //     top: 0px;
    //     left: 320px;
    //     background: ${color.dark};
    //     mix-blend-mode: exclusion;
    // }
`;

const LineAnim = keyframes`
    50% {
        width: 3px;
    }
`;

const HoverCtaAnim = keyframes`
    50% {
        opacity: 0.7;
    }
`;

const StyledRoadmapPosition = styled.div`
    display: inline-block;
    width: 536px;
    margin: 0 auto;

    &.mobile {
        width: auto;
        max-width: 340px;
    }
`;

const StyledRoadmapListContainer= styled.div`
    display: none;
    text-align: left;
    padding: 0 20px;

    .tooltip-container {
        width: 300px;
        padding: 16px 12px;
        background: ${color.black};
        backdrop-filter: blur(6px);
        
        &.show {
            opacity: 0.9 !important;
        }

        @media only screen and (max-width: 600px) {
            max-width: 70vw;
        }

        ul {
            border: none;
            li {
                list-style-type: disc;
                margin-bottom: 10px;
            }
            
            li:first-child {
                margin-top: 10px;
            }
        }
    }

    p {
        margin: 0;
        padding: 0;
        display: inline-block;

        &.description {
            border-bottom: 1px solid ${color.font};
            cursor: pointer;
        }
    }
    
    ul, li {
        list-style: none;
        padding: 0;
        margin: 0;
    }

    ul {
        li {
            position: relative;
            margin-bottom: 30px;
            will-change: opacity;

            &:last-child span .circ {
                &:before {
                    position: absolute;
                    top: 50%;
                    left: 50%;
                    transform: translateY(-50%) translateX(-50%);
                    content: '';
                    height: 120px;
                    width: 20px;
                    margin-top: 60px;
                    border-radius: 12px;
                    background: ${color.background};
                }
            }

            span {
                position: relative;
                .circ {
                    position: absolute;
                    display: block;
                    top: 50%;
                    left: -40px;
                    transform: translateY(-50%) translate3d(0,0,0);
                    height: 40px;
                    width: 40px;
                    border-radius: 18px;
                    background-color: #1A1B1C;
                    will-change: opacity;

                    &.complete:after {
                        background: ${color.dark};
                    }

                    &:after {
                        position: absolute;
                        top: 50%;
                        left: 50%;
                        transform: translateY(-50%) translateX(-50%);
                        content: '';
                        height: 20px;
                        width: 20px;
                        border: 2px solid ${color.dark};
                        border-radius: 12px;
                        background: ${color.light};
                    }
                }
            }
        }
        padding-left: 20px;
        border-left: 2px solid ${color.purple};
    }

    ul ul {
        margin-left: 10px;
        margin-top: 26px;
        border-left: 2px solid ${color.green};
    }
    

    @media only screen and (max-width: 930px) {
        display: block;
    }
`;

const StyledLabelOffset = styled.div<{
    posXLeft: string | undefined, 
    posXRight: string | undefined, 
    posY: string | undefined,
    width: string | undefined
}>`
    ${({width}) => width && `width: ${width};`}
    ${({posY}) => posY && `top: ${posY};`}
    ${({posXLeft}) => posXLeft && `left: calc(100% + ${posXLeft}) !important;`}
    ${({posXRight}) => posXRight && `right: calc(100% + ${posXRight}) !important;`}
`;

const StyledRoadmapDiagramContainer = styled.div`
    position: relative;
    display: flex;
    width: 200px;
    margin: 25px;
    flex-direction: column;
    flex-wrap: nowrap;
    justify-content: space-between;
    align-items: left;
    transform: rotateZ(-10deg);

    @media only screen and (max-width: 930px) {
        display: none;
    }

    &.mint {
        position: absolute;
        top: -50px;
        left: 170px;
        transform: rotateZ(-40deg);

        &:before {
            position: absolute;
            content: '';
            width: 2px;
            top: -3px;
            left: 30px;
            transform: rotateZ(-45deg);
            height: ${lineLength}px;
            background: ${color.dark};
            transform: rotateZ(-20deg);
        }
    }

    .hover {
        position: absolute;
        top: 0;
        left: 0;
        width: 100%;
        height: 100%;
        cursor: pointer;

        &.noHover {
            cursor: auto;
            .label:after {
                display: none;
            }
        }

        &:hover {
            .label {
                z-index: 9;
                opacity: 1;
                .mint-label {
                    height: auto;
                    opacity: 1;
                }
            }
            .description {
                height: auto;
                opacity: 1;
            }
        }

        .label:hover {
            z-index: 9;
            opacity: 1;
            .mint-label {
                height: auto;
                opacity: 1;
            }
        }
    }

    .label {
        text-align: left;
        transform-origin: top left;
        transition: opacity 0.1s ease-in 0s;
        position: absolute;
        display: inline-block;
        z-index: 8;
        background: ${color.black};
        border-radius: 6px;
        right: 130%;
        right: calc(100% + 10px);
        transform: rotateZ(10deg);
        font-size: 16px;
        padding: 10px;
        opacity: 1;
        pointer-events: auto;
        min-width: 130px;

        &:after {
            position: absolute;
            content: '';
            top: 14px;
            right: 14px;
            height: 0;
            width: 0;
            border: 6px solid transparent;
            border-right: 10px solid ${convertHexToRGBA(color.purple, 0.9)};
        }

        &.mint-parent {
            background: ${color.lessDark};
            color: ${color.fontDark};

            &:after {
                border-right: 10px solid ${convertHexToRGBA(color.green, 0.9)};
            }
        }

        &.mint {
            transform: rotateZ(50deg);
            min-width: 130px;
            font-size: 14px;
            background: ${color.lessDark};
            color: ${color.fontDark};

            &:after {
                border-right: 10px solid ${convertHexToRGBA(color.green, 0.9)};
            }

            .mint-label {
                transition: opacity 0.1s ease-in 0s;
                display: block;
                height: 0px;
                overflow: hidden;
                opacity: 0;
                color: ${color.fontDark};
            }
        }

        ul {
            margin: 20px;
            padding: 0;
        }
    }

    .percentage {
        font-size: 16px;
        display: block;
    }
    

    .description {
        transition: opacity 0.1s ease-in 0s;
        display: block;
        min-width: 200px;
        max-width: 240px;
        font-size: 14px;
        line-height: 18px;
        opacity: 0;
        height: 0px;
        overflow: hidden;
    }

    .node {
        position: relative;
        flex-shink: 0;

        &:nth-of-type(even) {
            align-self: flex-end;

            .label {
                right: auto;
                left: 130%;
                left: calc(100% + 10px);
            }

            &:after {
                right: auto;
                left: -8px;
                transform: rotateZ(45deg);
            }

            &.circCirc:after {
                left: -45px;
                transform: rotateZ(50deg);
            }

            &.circHex:after {
                left: -35px;
                transform: rotateZ(50deg);
            }

            &.hexCirc:after {
                left: -20px;
                transform: rotateZ(50deg);
            }
        }

        &:after {
            pointer-events: none;
            position: absolute;
            right: -8px;
            top: 86%;
            content: '';
            width: 2px;
            transform: rotateZ(-45deg);
            height: ${lineLength}px;
            background: ${color.dark};
            // animation: ${LineAnim} 1s linear infinite;
        }

        &.last:after {
            display: none;
        }

        &.circCirc:after {
            right: -45px;
            top: 49%;
            transform: rotateZ(-50deg);
            height: ${lineLength * 3}px;
        }

        &.circHex:after {
            right: -35px;
            top: 49%;
            transform: rotateZ(-50deg);
            height: ${lineLength * 2.2}px;
        }

        &.hexCirc:after {
            right: -20px;
            top: 76%;
            transform: rotateZ(-50deg);
            height: ${lineLength * 2.2}px;
        }
    }
`;

const StyledHexagonWrapper = styled.div`
    position: relative;
    height: 90px;
    width: 90px;

    span.label-milestone {
        position: absolute;
        top: 50%;
        left: 50%;
        font-weight: 600;
        transform: translateX(-50%) translateY(-50%) rotateZ(10deg);
        color: ${color.font};
    }

    &.complete {
        svg {
            stroke-dashoffset: 0 !important;
            fill: ${color.dark};
        }
        span.label-milestone {
            color: ${color.light};
        }
    }
`;

const StyledHexagon = styled(Hexagon)`
    max-width: 100%;
    max-height: 100%;
    stroke: ${color.dark};
    stroke-width: 5px;
    stroke-dashoffset: 0;
    stroke-dasharray: 1;
    fill: ${color.black};

    &.p10 {
        stroke-dashoffset: 0.90;
    }
    &.p25 {
        stroke-dashoffset: 0.75;
    }
    &.p50 {
        stroke-dashoffset: 0.50;
    }
    &.p75 {
        stroke-dashoffset: 0.25;
    }
    &.p100 {
        stroke-dashoffset: 0;
    }
`;

const StyledCircleNode = styled.div`
    height: 24px;
    width: 24px;
    margin: 33px;
    border: 2px solid ${color.dark};
    border-radius: 20px;
    background: ${color.light};

    &.complete {
        background: ${color.dark};
    }

    // animation: ${HoverCtaAnim} 3s linear infinite;
`;
