import React from 'react';
import styled from '@emotion/styled';
import { 
    PageOptions,
    StyledPageCenter,
    StyledSectionTitle,
    useLocale,
    color
} from '../utility';
import twitterSvg from '../asset/twitter.svg';


export function PageTeam(
    { id }: PageOptions
) {
    const locale = useLocale();

    return (
        <div id={id}>
            <StyledPageCenter>
                <StyledSectionTitle>{locale.section_title.team}</StyledSectionTitle>
                <StyledPage>
                    {locale.team.map((team) => {
                        return (
                            <StyledTeamMember key={Math.random()}>
                                <img className="avatar" src={team.pic_ext} />
                                {!!team.twitter && 
                                    <a target="_blank" rel="noreferrer" href={`https://twitter.com/${team.twitter}`}>@{team.twitter}</a>
                                }
                                <div className="title">{team.title}</div>
                            </StyledTeamMember>
                        )
                    })}
                </StyledPage>
            </StyledPageCenter>
            
        </div>
    );
}


const StyledPage = styled.div`
    display: flex;
    flex-direction: row;
    flex-wrap: wrap;
    justify-content: space-around;
    align-items: center;
    max-width: 800px;
    margin: 0 auto;
    padding-bottom: 20px;

    @media only screen and (max-width: 760px) {
        max-width: 400px;
    }
`;

const StyledTeamMember = styled.div`
    position: relative;
    background: ${color.black};
    width: 160px;
    min-height: 160px;
    border-radius: 6px;
    text-align: center;
    padding: 0 0 15px 0;
    margin: 0 10px 20px 10px;
    overflow: hidden;

    .avatar {
        max-width: 100%;
        margin-bottom: 10px;
    }

    .title {
        padding: 10px 10px 0 10px;
    }

    a {
        transition: opacity 0.1s linear 0s;
        color: ${color.font};
        font-weight: 500;
        font-size: 17px;
        text-decoration: none;
        color: ${color.purple};

        &:hover {
            opacity: 0.8;
        }
    }
`;