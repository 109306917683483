export const menuStyles = {
    bmBurgerButton: {
      position: 'fixed',
      width: '36px',
      height: '30px',
      left: '26px',
      top: '25px'
    },
    bmBurgerBars: {
      background: '#ff00ff'
    },
    bmBurgerBarsHover: {
      background: '#00ff00'
    },
    bmCrossButton: {
      height: '24px',
      width: '24px'
    },
    bmCross: {
      background: '#FFFFFF'
    },
    bmMenuWrap: {
      position: 'fixed',
      height: '100vh'
    },
    bmMenu: {
      background: '#1A1B1C',
      padding: '2.5em 1.5em 0',
      fontSize: '1.15em'
    },
    bmMorphShape: {
      fill: '#373a47'
    },
    bmItemList: {
      color: '#b8b7ad',
      padding: '0.8em',
      height: 'auto',
    },
    bmItem: {
      display: 'block',
      marginBottom: '20px',
    },
    bmOverlay: {
      position: 'fixed',
      top: '0',
      left: '0',
      height: '100vh',
      background: 'rgba(0, 0, 0, 0.3)'
    }
  }