export const en = {
    menu: {
        roadmap: "Roadmap",
        rarity: "Rarity",
        team: "Team"
    },
    footer: {
        contract_addr: "Coming Soon",
        copyright: "Bicameral"
    },
    section_title: {
        roadmap: "Roadmap",
        rarity: "Rarity",
        team: "Team"
    },
    description: "Dreams in Pursuit of Consciousness",
    introText: "A first of it's kind NFT project that uses brainwaves and AI to generate unique pieces of digital art",
    cta_wallet: "Connect Wallet",
    coming_soon: "Coming Soon",
    roadmap: {
        steps_mint: [
            {
                label: "Giveaway of 10 NFTs to 10 Discord community members",
                percentage: 10,
                posY: '-46px',
                posXLeft: '25px',
            },
            {
                label: "Free airdrop of 25 Bicamerals to 25 community members",
                percentage: 25,
                posY: '16px',
                posXLeft: '25px',
            },
            {
                label: "AMA with Bicameral project team, donation to local non-profit",
                percentage: 50,
                posY: '-22px',
                width: '150px',
                posXLeft: '-68px',
            },
            {
                label: "Submission to Rarity Tools, and Rarity Sniper",
                percentage: 75,
                posY: '16px',
                posXLeft: '25px',
            },
            {
                label: "10 ETH Donation to Top 3 Community-Nominated Non-Profit Organization",
                percentage: 100,
                posY: '16px',
                posXLeft: '25px',
            }
        ],
        steps_launch: [
            {
                label: "Whitelist Presale",
                description: "",
                posY: '-36px',
                complete_or_in_progress: true,
            },
            {
                label: "Team Mint",
                complete_or_in_progress: false,
                posY: '-8px',
                posXLeft: '18px',
                description: "100 Bicamerals will by minted by the team to be used for promotional giveaways and gifts.",
            },
            {
                label: "Presale Mint",
                description: "",
                posY: '-8px',
                complete_or_in_progress: false,
                percentage_emphasis: 10
            },
            {
                label: "Public Mint",
                complete_or_in_progress: false,
                posY: '-30px',
                posXLeft: '0px',
                description: "Includes initial Class selection. Those who mint a Bicameral will have the ability to choose from 50 classes (creatures or objects). Class type is one of the properties that flows into rarity, allowing users some autonomy over the rarity of their Bicameral. The less a class is chosen, the rarer the Bicameral will be.",
                percentage_emphasis: 25,
                connect_steps_mint: true
            },
            {
                label: "Reveal",
                complete_or_in_progress: false,
                posY: '-50px',
                posXRight: '8px',
                description: "Revealed tokens available on Secondary Markets."
            },
            {
                label: "Evolution 1",
                percentage_emphasis: 50,
                posY: '-60px',
                posXLeft: '-70px',
                complete_or_in_progress: false,
                description: "First decision of whether or not holders want to evolve their Bicameral. If they elect to evolve, holders have 2 options: <ul><li>Select from a pre-recorded list of situational EEG data - on psychedelics, having sex, on a run, deep work, and so on.</li><li>Upload their own EEG data in csv format to the Bicameral website.</li></ul>NFT art will be changed, showing the initial class \"morphing\" into a second class for an enriched experience."
            },
            {
                label: "Evolution 2",
                percentage_emphasis: 75,
                posY: '-20px',
                posXRight: '8px',
                complete_or_in_progress: false,
                description: "Same as Evolution 1, but a third class is added to the first and second morphing classes."
            },
            {
                label: "Artist Tool",
                percentage_emphasis: 100,
                posY: '-40px',
                posXLeft: '-20px',
                width: '260px',
                complete_or_in_progress: false,
                description: "A tool consisting of an API + UI for artists to create their own off-collection pieces using Bicameral's neural networks. Users may upload images (for texture and style), and either use their own EEG reading or use Bicameral's presets, and fuse together colors and patterns that represent their feelings to create novel art."
            }
        ]
    },
    team: [
        {
            twitter: "Caseyblue_",
            title: "Strategy, NFT Prophet",
            pic_ext: "caseyblue_.jpg"
        },
        {
            twitter: "josh_maldonado",
            title: "Comms Manager, NFT Advisor",
            pic_ext: "josh_maldonado.jpg"
        },
        {
            twitter: "simonszalai",
            title: "Machine Learnist, Artist Visionary",
            pic_ext: "simonszalai.jpg"
        },
        {
            twitter: "trakout",
            title: "World Wide Web (Three) Developer",
            pic_ext: "trakout.jpg"
        }
    ],
    rarity: {
        points: [
            "Most attributes are random, some are locked in across evolutions.",
            "Certain attributes that drive rarity can also be user-defined.",
            "Evolutions influence rarity.",
            "Support for rarity indexing services.",
            "Roadmapped: Rarity analytics tooling."
        ],
        diagram: [
            {
                "taste": "EEG Data",
                "Mint": 0.25,
                "Evolution 1": 0.95,
                "Evolution 2": 0.34
            },
            {
                "taste": "Class",
                "Mint": 0.15,
                "Evolution 1": 0.60,
                "Evolution 2": 0.85
            },
            {
                "taste": "Style",
                "Mint": 0.88,
                "Evolution 1": 0.34,
                "Evolution 2": 0.63
            },
            {
                "taste": "Electrode Location",
                "Mint": 0.30,
                "Evolution 1": 0.92,
                "Evolution 2": 0.70
            },
            {
                "taste": "Wavelength",
                "Mint": 0.91,
                "Evolution 1": 0.35,
                "Evolution 2": 0.23
            },
            {
                "taste": "Amplitude",
                "Mint": 0.91,
                "Evolution 1": 0.98,
                "Evolution 2": 0.23
            },
            {
                "taste": "Transform",
                "Mint": 0.21,
                "Evolution 1": 0.75,
                "Evolution 2": 0.23
            }
        ]
    }
};