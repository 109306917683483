import React from 'react';
import styled from '@emotion/styled';
import { useLocale } from '../utility';

export function Footer() {
    const locale = useLocale();

    return (
        <StyledFooter>
            Contract Address: {locale.footer.contract_addr}<br />
            <StyledCopyright>
                &#169; {new Date().getFullYear()} {locale.footer.copyright}
            </StyledCopyright>
        </StyledFooter>
    )
}

const StyledFooter = styled.div`
    font-size: 12px;
    text-align: center;
    opacity: 0.5;
    line-height: 22px;
    padding: 40px 10px 40px;
`;

const StyledCopyright = styled.div`
    @media only screen and (max-width: 358px) {
        margin-top: 10px;
    }
`;