import React from 'react';
import styled from '@emotion/styled';
import { color, WIDTH_TABLET } from './utility';
import { Header, Footer } from './component';
import {
  PageHome,
  PageRarity,
  PageRoadmap,
  PageTeam
} from './page';

function App() {
  // const isDev = new URLSearchParams(window.location.search)?.get('devmode') === 'yesletmein' ? true : false;

  return (
    <StyledApp className="app">
      <Header />
      <PageHome id="home" showIntroText={true} />
      <PageRoadmap id="roadmap" />
      <PageRarity id="rarity" />
      <PageTeam id="team" />
      <Footer />
    </StyledApp>
  );
}

// const StyledComingSoon = styled.h1`
//   margin: 30px;
//   text-align: center;
// `;

const StyledApp = styled.div`
  height: 100%;
  min-height: 100%;
  min-width: 310px;
  color: ${color.font};

  &.coming-soon {
    @media only screen and (max-width: 824px) {
      position: absolute;
      height: auto;
      min-height: 0;
      width: 100%;
      top: 50%;
      left: 0;
      transform: translateY(-50%);
    }
  }
`;

export default App;
