import React from 'react';
import styled from '@emotion/styled';
import { ResponsiveRadar } from '@nivo/radar';
import { 
    PageOptions, 
    StyledPageCenter,
    StyledSectionTitle,
    useLocale,
    WIDTH_TABLET,
    WIDTH_MOBILE,
    color
} from '../utility';

export function PageRarity(
    { id }: PageOptions
) {
    const locale = useLocale();

    const tooltip = {
        container: {
            background: 'black',
            color: '#f2f2f2',
            fontSize: 'inherit',
            borderRadius: '2px',
            boxShadow: '0 1px 2px rgba(0, 0, 0, 0.25)',
            padding: '5px 9px'
        },
        basic: {
            whiteSpace: 'pre',
            display: 'flex',
            alignItems: 'center'
        },
        table: {},
        tableCell: {
            padding: '3px 5px'
        }
    };

    return (
        <StyledPageCenter id={id}>
            <StyledSectionTitle style={{marginTop:'25px'}}>Rarity</StyledSectionTitle>
            <StyledPage>
                <ul>
                    {locale.rarity.points.map((p) => {
                        return (
                            <RarityLi key={Math.random()}>{p}</RarityLi>
                        );
                    })}
                </ul>
                <StyledDiagramWrapper>
                    <ResponsiveRadar
                        data={locale.rarity.diagram}
                        // @ts-ignore
                        theme={{textColor: '#f2f2f2', tooltip}}
                        keys={[ 'Mint', 'Evolution 1', 'Evolution 2' ]}
                        indexBy="taste"
                        valueFormat=">-.2%"
                        margin={{ top: 40, right: 40, bottom: 40, left: 80 }}
                        borderColor={{ from: 'color' }}
                        gridLabelOffset={14}
                        gridShape="linear"
                        dotSize={10}
                        dotColor="black"
                        dotBorderWidth={2}
                        colors={["#fdfdfd", "#00ff00", "#ff00ff"]}
                        blendMode="color-dodge"
                        fillOpacity={0.3}
                        motionConfig="wobbly"
                        legends={[
                            {
                                anchor: 'top-left',
                                direction: 'column',
                                translateX: -50,
                                translateY: -40,
                                itemWidth: 80,
                                itemHeight: 20,
                                itemTextColor: '#f2f2f2',
                                symbolSize: 12,
                                symbolShape: 'circle',
                                effects: [
                                    {
                                        on: 'hover',
                                        style: {
                                            itemTextColor: '#ffffff'
                                        }
                                    }
                                ]
                            }
                        ]}
                    />
                </StyledDiagramWrapper>
            </StyledPage>
        </StyledPageCenter>
    );
}

const StyledDiagramWrapper = styled.div`
    height: 400px;
    min-width: 500px;
    padding-right: 30px;

    @media only screen and (max-width: ${WIDTH_TABLET}px) {
        min-width: 350px;
    }

    @media only screen and (max-width: 660px) {
        margin-top: 40px;
    }
`;

const StyledPage = styled.div`
    margin: 0 auto;
    display: flex;
    flex-direction: row;
    flex-wrap: nowrap;
    justify-content: space-around;
    align-items: flex-start;

    ul {       
        margin: 0;
        li {
            padding: 0;
            line-height: 1.6em;
        }
    }

    @media only screen and (max-width: 660px) {
        flex-direction: column;
        align-items: center;
    }
`;

const RarityLi = styled.li(
    (props) => `
        margin: 12px 0;
        letter-spacing: 0.2px;
        font-size: 17px;
        line-height: 24px!important;
    `
)